import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './mobile-menu-top.scss';
import Close from '../mobile-menu-close/mobile-menu-close';
import Logo from '../mobile-menu-logo/mobile-menu-logo';

const MobileMenuTop = (props) => {
  const { className, handleCloseMenu } = props;
  const ROOT_CLASS = 'mobile-menu__top';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      <Logo />
      <Close onClick={handleCloseMenu} />
    </div>
  );
};

MobileMenuTop.propTypes = {
  className: PropTypes.string,
  handleCloseMenu: PropTypes.func,
};

MobileMenuTop.defaultProps = {
  className: '',
  handleCloseMenu: () => {},
};

export default MobileMenuTop;
