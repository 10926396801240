import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './mobile-menu.scss';
import Top from './mobile-menu-top/mobile-menu-top';
import Footer from './mobile-menu-footer/mobile-menu-footer';
import Body from './mobile-menu-body/mobile-menu-body';

const MobileMenu = (props) => {
  const { className, isOpen, handleCloseMenu } = props;
  const ROOT_CLASS = 'mobile-menu';
  const ROOT_ACTIVE_CLASS = `${ROOT_CLASS}_${isOpen ? 'open' : 'close'}`;
  const CONTENT_CLASS = 'mobile-menu__content';
  const WRAP_CLASS = 'mobile-menu__wrap';
  const rootClass = mergeClasses(ROOT_CLASS, ROOT_ACTIVE_CLASS, className);

  return (
    <div className={rootClass}>
      <div className={CONTENT_CLASS}>
        <div className={WRAP_CLASS}>
          <Top handleCloseMenu={handleCloseMenu} />
          <Body />
          <Footer />
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  handleCloseMenu: PropTypes.func,
};

MobileMenu.defaultProps = {
  className: '',
  isOpen: false,
  handleCloseMenu: () => {},
};

export default MobileMenu;
