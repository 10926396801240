import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { mergeClasses } from '../../../../libs/helpers';
import MenuItem from '../menu-item/menu-item';
import './menu-list.scss';

const MenuList = (props) => {
  const {
    className, items, itemClass, linkClass, activeLinkClass,
  } = props;
  const ROOT_CLASS = 'menu__list';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const LINK_CLASS = 'menu__link';
  const linkClassName = mergeClasses(LINK_CLASS, linkClass);

  return (
    <ul className={rootClass}>
      {items.map((item, key) => (
        <MenuItem key={key} className={itemClass}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Link activeClassName={activeLinkClass} className={linkClassName} {...item} />
        </MenuItem>
      ))}
    </ul>
  );
};

MenuList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  itemClass: PropTypes.string.isRequired,
  linkClass: PropTypes.string,
  activeLinkClass: PropTypes.string,
};

MenuList.defaultProps = {
  className: '',
  items: [],
  linkClass: '',
  activeLinkClass: '',
};

export default MenuList;
