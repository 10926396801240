import React from 'react';
import './header-phone.scss';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import {
  Phone as PhoneContact,
} from '../../contacts/contacts';

const HeaderPhone = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'header__phone';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <PhoneContact className={rootClass} />
  );
};

HeaderPhone.propTypes = {
  className: PropTypes.string,
};

HeaderPhone.defaultProps = {
  className: '',
};

export default HeaderPhone;
