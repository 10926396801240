import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './avatar-box-item.scss';

const AvatarBoxItem = (props) => {
  const { className, children, icon } = props;
  const ROOT_CLASS = 'avatar-box__item';
  const ICON_CLASS = 'avatar-box__item-icon';
  const TEXT_CLASS = 'avatar-box__item-text';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const Icon = React.cloneElement(icon, {
    className: mergeClasses(ICON_CLASS, className),
  });

  return (
    <li className={rootClass}>
      {Icon}
      <span className={TEXT_CLASS}>{children}</span>
    </li>
  );
};

AvatarBoxItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

AvatarBoxItem.defaultProps = {
  className: '',
  children: null,
};

export default AvatarBoxItem;
