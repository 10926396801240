import React from 'react';
import './burger-line.svg';

const Line = () => (
  <svg>
    <use xlinkHref="#burger-line" />
  </svg>
);

export default Line;
