import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { mergeClasses } from '../../../../libs/helpers';
import AvatarBoxItem from '../avatar-box-item/avatar-box-item';
import { User, SignOut } from '../../icons/icons';
import './avatar-box-list.scss';

const AvatarBoxList = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'avatar-box__list';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const items = [
    {
      icon: <User />,
      href: '/',
      component: 'Личный кабинет',
    },
    {
      icon: <SignOut />,
      href: '/',
      component: 'Выйти',
    },
  ];

  return (
    <ul className={rootClass}>
      {items.map((item, key) => (
        <AvatarBoxItem icon={item.icon} key={key}>
          <Link to={item.href}>{item.component}</Link>
        </AvatarBoxItem>
      ))}
    </ul>
  );
};

AvatarBoxList.propTypes = {
  className: PropTypes.string,
};

AvatarBoxList.defaultProps = {
  className: '',
};

export default AvatarBoxList;
