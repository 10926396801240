import React from 'react';
import './header-menu.scss';
import PropTypes from 'prop-types';
import Menu from '../../menu/menu';
import { mergeClasses } from '../../../../libs/helpers';

const HeaderMenu = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'header__menu';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <Menu
      className={rootClass}
      itemClass="header__menu-item"
      linkClass="header__menu-link"
      activeLinkClass="header__menu-link_active"
      listClass="header__menu-list"
    />
  );
};

HeaderMenu.propTypes = {
  className: PropTypes.string,
};

HeaderMenu.defaultProps = {
  className: '',
};

export default HeaderMenu;
