import React from 'react';
import './burger-close.svg';

const Close = () => (
  <svg>
    <use xlinkHref="#burger-close" />
  </svg>
);

export default Close;
