import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './burger.scss';

const Burger = (props) => {
  const { className, children } = props;
  const ROOT_CLASS = 'burger';
  const LINES_CLASS = 'burger__lines';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button {...props} type="button" className={rootClass}>
      <span className={LINES_CLASS}>{children}</span>
    </button>
  );
};

Burger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Burger.defaultProps = {
  className: '',
};

export default Burger;
