import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './menu.scss';
import MenuList from './menu-list/menu-list';
import items from './items';

const Menu = (props) => {
  const { className, listClass, ...other } = props;

  const ROOT_CLASS = 'menu';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <MenuList className={listClass} items={items} {...other} />
    </div>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  listClass: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
  listClass: '',
};

export default Menu;
