import PropTypes from 'prop-types';
import React from 'react';
import './contact.scss';
import Icon from '../tz-icon/icon';
import { mergeClasses } from '../../../libs/helpers';

const Contact = (props) => {
  const {
    mod, className, text, href, textClass,
  } = props;

  const ROOT_CLASS = 'contact';
  const modClass = mod ? `contact_${mod}` : null;
  const rootClass = mergeClasses(ROOT_CLASS, className, modClass);

  const TEXT_CLASS = 'contact__text';
  const textClassName = mergeClasses(TEXT_CLASS, textClass);

  const contactText = text ? <span className={textClassName}>{text}</span> : '';

  return href ? (
    <a className={rootClass} href={href}>
      <Icon mod={mod} className="contact__icon" />
      {contactText}
    </a>
  ) : (
    <span className={rootClass}>
      <Icon mod={mod} className="contact__icon" />
      {contactText}
    </span>
  );
};

Contact.propTypes = {
  mod: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  textClass: PropTypes.string,
};

Contact.defaultProps = {
  className: '',
  text: '',
  href: '',
  textClass: '',
};

export default Contact;
