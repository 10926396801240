/* eslint-disable */
// todo alex исправить импорты
import React from "react";
import Contact from "../contact/contact";
import siteData from '../../__mocks__/commonSiteData'

export const Mail = (props) => {
    return <Contact href={siteData.mail.href} mod="mail" text={siteData.mail.text} {...props}/>
};

export const Phone = (props) => {
    return <Contact href={siteData.phone.href} mod="phone" text={siteData.phone.text} {...props}/>
};

export const PhoneIcon = (props) => {
    return <Contact href={siteData.phone.href} mod="phone" {...props}/>
};

export const Mark = (props) => {
    return <Contact mod="mark" text={siteData.address} {...props}/>
};

export const Ig = (props) => {
    return <Contact href={siteData.socials.ig} mod="ig" {...props}/>
};

export const Vk = (props) => {
    return <Contact href={siteData.socials.vk} mod="vk" {...props}/>
};

export const Fb = (props) => {
    return <Contact href={siteData.socials.fb} mod="fb" {...props}/>
};

export const Wa = (props) => {
    return <Contact href={siteData.messengers.wa} mod="wa" {...props}/>
};

export const Vb = (props) => {
    return <Contact href={siteData.messengers.vb} mod="vb" {...props}/>
};

export const Tg = (props) => {
    return <Contact href={siteData.messengers.tg} mod="tg" {...props}/>
};

export default [
    Mail,
    Phone,
    Mark,
    Ig,
    Vk,
    Fb,
    Wa,
    Vb,
    Tg,
];

