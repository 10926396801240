import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import Button from '../../buttons/button/button';
import './header-enter.scss';

const HeaderEnter = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'header__enter-button button_small-text button_login';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <Button theme="black" outline borderRadius="extra" className={rootClass}>Войти</Button>
  );
};

HeaderEnter.propTypes = {
  className: PropTypes.string,
};

HeaderEnter.defaultProps = {
  className: '',
};

export default HeaderEnter;
