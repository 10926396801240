/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './tz-icon.scss';

const Icon = (props) => {
  const { className, mod, src, onClick, role } = props;
  let rootClass = 'tz-icon';
  if (mod) rootClass = `${rootClass} tz-icon_${mod}`;
  if (className) rootClass = `${rootClass} ${className}`;

  const href = src || mod;
  // eslint-disable-next-line global-require
  require(`./icons/${mod}/${href}.svg`);

  return (
    <span className={rootClass} onClick={onClick} role={role}>
      <svg>
        <use xlinkHref={`#${href}`} />
      </svg>
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  mod: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
  role: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  mod: '',
  src: '',
  onClick: () => {},
  role: '',
};

export default Icon;
