import React from 'react';
import PropTypes from 'prop-types';
import RootButton from './root-button';

const BaseButton = (Component, props) => {
  const { children } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RootButton Component={Component} {...props}>{children}</RootButton>;
};

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseButton;
