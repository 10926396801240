import React from 'react';
import PropTypes from 'prop-types';
import LayoutWithContainer from '../layout-with-container';
import { mergeClasses } from '../../../libs/helpers';
import Back from '../back/back';

const StandardPage = (props) => {
  const {
    pageName, children, title, backHref, backText,
  } = props;

  const ROOT_PAGE_TITLE_CLASS = 'page__title page__title_h1 page__title_standard-page';
  const PAGE_TITLE_CLASS = `${pageName}__title`;
  const pageTitleClass = mergeClasses(ROOT_PAGE_TITLE_CLASS, PAGE_TITLE_CLASS);

  const CONTENT_CLASS = 'page__content';
  const BACK_CLASS = 'page__back';
  const contentClass = mergeClasses(CONTENT_CLASS, `${pageName}__content`);

  return (
    <LayoutWithContainer pageName={pageName}>
      {backHref && <Back className={BACK_CLASS} href={backHref} text={backText} />}

      {/* todo alex спросить у Юры про отступы page__title_main */}
      {title && <h1 className={pageTitleClass}>{title}</h1>}

      <div className={contentClass}>{children}</div>
    </LayoutWithContainer>
  );
};

StandardPage.propTypes = {
  pageName: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  backHref: PropTypes.string,
  backText: PropTypes.string,
};

StandardPage.defaultProps = {
  children: '',
  title: null,
  backHref: '',
  backText: 'Назад',
};

export default StandardPage;
