import React from 'react';
import './footer-bottom.scss';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';

const FooterBottom = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'footer__bottom';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  // todo alex make Copy, Designer components

  return (
    <div className={rootClass}>
      <div className="footer__copy">© 2019 TeziGrooming. Все права защищены</div>
      <div className="footer__designer">Дизайнер сайта Юрий Антонов</div>
    </div>
  );
};

FooterBottom.propTypes = {
  className: PropTypes.string,
};

FooterBottom.defaultProps = {
  className: '',
};

export default FooterBottom;
