import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import FooterWidget from '../footer-widget/footer-widget';
import items from '../../menu/items';
import './footer-body.scss';

const FooterBody = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'footer__body';

  const COL_CLASS = 'footer__col footer__col_widget';
  const ROW_CLASS = 'footer__row footer__row_widget';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const menuArray = items;

  const clientsArray = [
    {
      children: 'FAQ',
      to: '/faq',
    },
    {
      children: 'Список услуг',
      to: '/services-list',
    },
    {
      children: 'Карта сайта',
      to: '/site-map',
    },
  ];

  const careerArray = [
    {
      children: 'Вакансии',
      to: '/vacancies',
    },
  ];

  const rulesArray = [
    {
      children: 'Подарочные карты',
      to: '/gift-cards',
    },
    {
      children: 'Бонусная система',
      to: '/bonus-system',
    },
    {
      children: 'Годовые пакеты услуг',
      to: '/years-packages',
    },
    {
      children: 'Сертификаты',
      to: '/certificates',
    },
    {
      children: 'Персональные скидки',
      to: '/personal-discount',
    },
    {
      children: 'Обработка персональных данных',
      to: '/personal-data-processing',
    },
    {
      children: 'Публичная оферта',
      to: '/public-offer',
    },
    {
      children: 'Политика Cookie',
      to: '/cookie',
    },
  ];

  return (
    <div className={rootClass}>
      <div className={ROW_CLASS}>

        <div className={COL_CLASS}>
          <FooterWidget title="Клиентам" items={clientsArray} />
          <FooterWidget title="Карера" items={careerArray} />
        </div>

        <div className={COL_CLASS}>
          <FooterWidget title="Меню" items={menuArray} />
        </div>

        <div className={COL_CLASS}>
          <FooterWidget title="Правила и условия" items={rulesArray} />
        </div>

      </div>
    </div>
  );
};

FooterBody.propTypes = {
  className: PropTypes.string,
};

FooterBody.defaultProps = {
  className: '',
};

export default FooterBody;
