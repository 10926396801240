import React from 'react';
import PropTypes from 'prop-types';
import './footer-widget.scss';
import { Link } from 'gatsby';
import { mergeClasses } from '../../../../libs/helpers';

const FooterWidget = (props) => {
  const { className, title, items } = props;
  const ROOT_CLASS = 'footer__widget';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  // todo alex make Title, List, Item components

  return (
    <div className={rootClass}>
      <div className="footer__widget-title">{title}</div>

      <ul className="footer__widget-list">
        {items.map((item, key) => (
          <li className="footer__widget-item" key={key}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Link className="footer__widget-link" {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterWidget.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
};

FooterWidget.defaultProps = {
  className: '',
  title: '',
  items: [],
};

export default FooterWidget;
