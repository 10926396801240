import React from 'react';
import PropTypes from 'prop-types';
import './header-address.scss';
import { mergeClasses } from '../../../../libs/helpers';
import {
  Mark as Address,
} from '../../contacts/contacts';

const HeaderAddress = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'header__address';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <Address className={rootClass} />
  );
};

HeaderAddress.propTypes = {
  className: PropTypes.string,
};

HeaderAddress.defaultProps = {
  className: '',
};

export default HeaderAddress;
