import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import Avatar from '../avatar/avatar';
import { Arrow } from '../icons/icons';
import AvatarBoxPopup from './avatar-box-popup/avatar-box-popup';
import AvatarBoxList from './avatar-box-list/avatar-box-list';
import './avatar-box.scss';

const AvatarBox = ({ className, size }) => {
  const ROOT_CLASS = 'avatar-box';
  const ICON_CLASS = 'avatar-box__icon';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const Icon = Arrow.down;

  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <button type="button" onClick={handleClick} className={rootClass}>
      <Avatar size={size} border="white" />
      <Icon className={ICON_CLASS} />
      <AvatarBoxPopup isOpen={isOpen}>
        <AvatarBoxList />
      </AvatarBoxPopup>
    </button>
  );
};

AvatarBox.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
};

AvatarBox.defaultProps = {
  className: '',
};

export default AvatarBox;
