import React from 'react';
import './mobile-menu-login.scss';
import LoginContainer from './mobile-menu-login-container';
import Avatar from '../../avatar/avatar';

const AVATAR_WRAP_CLASS = 'mobile-menu__login-icon-wrap';
const ICON_CLASS = 'mobile-menu__login-avatar';
const IconWrap = <div className={AVATAR_WRAP_CLASS}><Avatar className={ICON_CLASS} size="sm" /></div>;

const LoginAvatar = () => <LoginContainer icon={IconWrap} text="Мария Иванова" />;

export default LoginAvatar;
