import React from 'react';
import './footer.scss';
import Top from './footer-top/footer-top';
import Body from './footer-body/footer-body';
import Bottom from './footer-bottom/footer-bottom';
import { Fb, Ig, Vk } from '../contacts/contacts';

// todo alex make Row, Col, Socials components

const Footer = () => (
  <footer className="footer">
    <div className="footer__container container">

      <div className="footer__wrap">
        <ul className="footer__socials">
          <li className="footer__social"><Ig /></li>
          <li className="footer__social"><Vk /></li>
          <li className="footer__social"><Fb /></li>
        </ul>

        <div className="footer__inner">
          <Top />
          <Body />
        </div>
      </div>

      <Bottom />

    </div>
  </footer>
);

export default Footer;
