import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './mobile-menu-login.scss';
import Enter from './mobile-menu-login-enter';
import Avatar from './mobile-menu-login-avatar';

const MobileMenuLogin = (props) => {
  const { className, isLogin } = props;
  const ROOT_CLASS = 'mobile-menu__login';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <a href="/" className={rootClass}>
      {isLogin ? <Avatar /> : <Enter />}
    </a>
  );
};

MobileMenuLogin.propTypes = {
  className: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
};

MobileMenuLogin.defaultProps = {
  className: '',
};

export default MobileMenuLogin;
