import React from 'react';
import PropTypes from 'prop-types';

import '../styles/common.scss';
import { mergeClasses } from '../../libs/helpers';
import Layout from './layout';

const LayoutWithContainer = ({
  children, pageName, containerClass, mainClass,
}) => {
  const CONTAINER_CLASS = 'container';
  const containerClassName = mergeClasses(CONTAINER_CLASS, containerClass);

  return (
    <Layout pageName={pageName} mainClass={mainClass}>
      <div className={containerClassName}>
        {children}
      </div>
    </Layout>
  );
};

LayoutWithContainer.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  mainClass: PropTypes.string,
};

LayoutWithContainer.defaultProps = {
  mainClass: '',
  containerClass: '',
};

export default LayoutWithContainer;
