import React from 'react';
import PropTypes from 'prop-types';
import Img from './image';
import './avatar.scss';
import { mergeClasses } from '../../../libs/helpers';

/**
 * border: white
 * size: sm | md | lg - default: sm
 */

const Avatar = ({ className, size, border }) => {
  const ROOT_CLASS = 'avatar';
  const BORDER_CLASS = 'avatar_border';
  const IMG_CLASS = 'avatar__img';

  const sizeClass = size ? `avatar_${size}` : '';
  const borderClass = border ? `${BORDER_CLASS} ${BORDER_CLASS}_${border}` : '';
  const rootClass = mergeClasses(ROOT_CLASS, className, sizeClass, borderClass);

  return (
    <span className={rootClass}>
      <Img className={IMG_CLASS} />
    </span>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
  border: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  border: '',
};

export default Avatar;
