import React from 'react';
import Burger from '../burger';
import Close from './close';

const BurgerClose = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Burger {...props}>
    <Close />
  </Burger>
);

export default BurgerClose;
