import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import Menu from '../../menu/menu';
import Login from '../mobile-menu-login/mobile-menu-login';
import './mobile-menu-body.scss';

const MobileMenuBody = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'mobile-menu__body';
  const MENU_CLASS = 'mobile-menu__menu';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      <Menu
        className={MENU_CLASS}
        itemClass="mobile-menu__item"
        listClass="mobile-menu__list"
      />
      <Login isLogin />
    </div>
  );
};

MobileMenuBody.propTypes = {
  className: PropTypes.string,
};

MobileMenuBody.defaultProps = {
  className: '',
};

export default MobileMenuBody;
