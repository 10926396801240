import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './avatar-box-popup.scss';

const AvatarBoxPopup = (props) => {
  const { className, isOpen, children } = props;

  const ROOT_CLASS = 'avatar-box__popup';
  const ROOT_OPEN_CLASS = `${ROOT_CLASS}_${isOpen ? 'open' : 'close'}`;
  const INNER_CLASS = 'avatar-box__popup-inner';

  const rootClass = mergeClasses(ROOT_CLASS, ROOT_OPEN_CLASS, className);

  return (
    <span className={rootClass}>
      <span className={INNER_CLASS}>{children}</span>
    </span>
  );
};

AvatarBoxPopup.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.element,
};

AvatarBoxPopup.defaultProps = {
  className: '',
  isOpen: false,
  children: null,
};

export default AvatarBoxPopup;
