import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import { mergeClasses } from '../../../../libs/helpers';

const RootButton = (props) => {
  const {
    Component, children, theme, className, iconLeft, iconRight, textClass, outline, borderRadius, onClick,
  } = props;

  const themeClass = theme && `button_theme button_theme_${theme}`;
  const borderRadiusClass = borderRadius && `button_border-radius button_border-radius_${borderRadius}`;
  const textRootClass = mergeClasses('button__text', textClass);
  const outlineClass = outline ? 'button_outline' : '';
  const rootClass = mergeClasses('button', className, themeClass, outlineClass, borderRadiusClass);

  const addIconPositionClass = (icon, iconPosition) => {
    const iconWrapClass = mergeClasses(`button__icon_${iconPosition}`, icon.props.className);

    return React.cloneElement(icon, {
      className: iconWrapClass,
    });
  };

  const iconLeftWrap = iconLeft && addIconPositionClass(iconLeft, 'left');
  const iconRightWrap = iconRight && addIconPositionClass(iconRight, 'right');

  return (
    <Component className={rootClass} onClick={onClick}>
      {iconLeft && iconLeftWrap}
      {textRootClass && <span className={textRootClass}>{children}</span>}
      {iconRight && iconRightWrap}
    </Component>
  );
};

RootButton.propTypes = {
  Component: PropTypes.elementType.isRequired,
  children: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['white', 'black', 'red', 'purple']),
  className: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  textClass: PropTypes.string,
  outline: PropTypes.bool,
  borderRadius: PropTypes.bool,
  onClick: PropTypes.func,
};

RootButton.defaultProps = {
  theme: 'white',
  className: '',
  iconLeft: null,
  iconRight: null,
  textClass: '',
  outline: false,
  borderRadius: false,
  onClick: () => {},
};

export default RootButton;
