/* eslint-disable */
// todo alex Исправить импорт
import React from 'react';
import Icon from '../tz-icon/icon';
import { mergeClasses } from '../../../libs/helpers';

export const AlertCircleIcon = {
  red(props) {
    const className = mergeClasses('tz-icon_alert-circle_red', props.className);

    return <Icon mod="alert-circle" {...props} className={className} />;
  },

  black(props) {
    const className = mergeClasses('tz-icon_alert-circle_black', props.className);

    return <Icon mod="alert-circle" {...props} className={className} />;
  },
};

export const Arrow = {
  down(props) {
    return <Icon mod="arrow" src="arrow-down" {...props} />;
  },
  left(props) {
    const className = mergeClasses('tz-icon_arrow_left', props.className);

    return <Icon mod="arrow" src="arrow-down" {...props} className={className} />;
  },
  right(props) {
    const className = mergeClasses('tz-icon_arrow_right', props.className);

    return <Icon mod="arrow" src="arrow-down" {...props} className={className} />;
  },
  up(props) {
    const className = mergeClasses('tz-icon_arrow_up', props.className);

    return <Icon mod="arrow" src="arrow-down" {...props} className={className} />;
  },
};

export const ArrowCircle = {
  down(props) {
    const className = mergeClasses('tz-icon_arrow-circle_down', props.className);

    return <Icon mod="arrow-circle" {...props} className={className} />;
  },
  left(props) {
    const className = mergeClasses('tz-icon_arrow-circle_left', props.className);

    return <Icon mod="arrow-circle" {...props} className={className} />;
  },
  right(props) {
    const className = mergeClasses('tz-icon_arrow-circle_right', props.className);

    return <Icon mod="arrow-circle" {...props} className={className} />;
  },
  up(props) {
    const className = mergeClasses('tz-icon_arrow-circle_up', props.className);

    return <Icon mod="arrow-circle" {...props} className={className} />;
  },
};

export const PlusCircle = (props) => <Icon mod="plus-circle" {...props} />;

export const MinusCircle = (props) => <Icon mod="minus-circle" {...props} />;

export const X = (props) => <Icon mod="x" {...props} />;

export const Clock = (props) => <Icon mod="clock" {...props} />;

export const Search = (props) => <Icon mod="search" {...props} />;

export const Eye = (props) => <Icon mod="eye" {...props} />;

export const EyeClose = (props) => <Icon mod="eye-close" {...props} />;

export const Calendar = (props) => <Icon mod="calendar" src="calendar-icon" {...props} />;

export const Download = (props) => <Icon mod="download" {...props} />;

export const SignIn = (props) => {
  const className = mergeClasses('tz-icon_sign-in', props.className);

  return <Icon mod="download" {...props} className={className} />;
};

export const Upload = (props) => <Icon mod="upload" {...props} />;

export const SignOut = (props) => {
  const className = mergeClasses('tz-icon_sign-out', props.className);

  return <Icon mod="upload" {...props} className={className} />;
};

export const User = (props) => <Icon mod="user" {...props} />;

export const Settings = (props) => <Icon mod="settings" {...props} />;

export const Gift = (props) => <Icon mod="gift" {...props} />;

export const Card = (props) => <Icon mod="card" {...props} />;

export const SimpleArrow = {
  down(props) {
    const className = mergeClasses('tz-icon_simple-arrow_down', props.className);

    return <Icon mod="simple-arrow" {...props} className={className} />;
  },
  left(props) {
    const className = mergeClasses('', props.className);

    return <Icon mod="simple-arrow" {...props} className={className} />;
  },
  right(props) {
    const className = mergeClasses('tz-icon_simple-arrow_right', props.className);

    return <Icon mod="simple-arrow" {...props} className={className} />;
  },
  up(props) {
    const className = mergeClasses('tz-icon_simple-arrow_up', props.className);

    return <Icon mod="simple-arrow" {...props} className={className} />;
  },
};

export const Checkbox = (props) => <Icon mod="checkbox" {...props} />;

export const Board = (props) => <Icon mod="board" {...props} />;

export const Mail = (props) => <Icon mod="mail" {...props} />;

export const Phone = (props) => <Icon {...props} mod="phone" />;

export const Mark = (props) => <Icon mod="mark" {...props} />;

export const IgIcon = (props) => <Icon mod="ig" {...props} />;

export const VkIcon = (props) => <Icon mod="vk" {...props} />;

export const FbIcon = (props) => <Icon mod="fb" {...props} />;

export const WaIcon = (props) => <Icon mod="wa" {...props} />;

export const VbIcon = (props) => <Icon mod="vb" {...props} />;

export const TgIcon = (props) => <Icon mod="tg" {...props} />;

export default [
  // AlertCircleIcon.red,
  // AlertCircleIcon.black,
  Arrow.down,
  Arrow.left,
  Arrow.right,
  Arrow.up,
  ArrowCircle.down,
  ArrowCircle.left,
  ArrowCircle.right,
  ArrowCircle.up,
  PlusCircle,
  MinusCircle,
  X,
  Clock,
  Search,
  Eye,
  EyeClose,
  Calendar,
  Download,
  SignIn,
  Upload,
  SignOut,
  User,
  Settings,
  Gift,
  Card,
  SimpleArrow.down,
  SimpleArrow.left,
  SimpleArrow.right,
  SimpleArrow.up,
  Checkbox,
  Board,
  Mail,
  Phone,
  Mark,
  IgIcon,
  VkIcon,
  FbIcon,
  WaIcon,
  VbIcon,
  TgIcon,
];
