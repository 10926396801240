import React from 'react';
import PropTypes from 'prop-types';
import './header-login.scss';
import { mergeClasses } from '../../../../libs/helpers';
import HeaderEnter from '../header-enter/header-enter';
import AvatarBox from '../../avatar-box/avatar-box';

const HeaderLogin = (props) => {
  const { className, isLogin } = props;
  const ROOT_CLASS = 'header__login';

  const rootClass = mergeClasses(ROOT_CLASS, className);
  return (
    <div className={rootClass}>
      {isLogin ? <AvatarBox size="sm" /> : <HeaderEnter />}
    </div>
  );
};

HeaderLogin.propTypes = {
  className: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
};

HeaderLogin.defaultProps = {
  className: '',
};

export default HeaderLogin;
