export default [
  {
    children: 'Главная',
    to: '/',
  },
  {
    children: 'Грумеры',
    to: '/groomers',
  },
  {
    children: 'Услуги',
    to: '/services',
  },
  {
    children: 'Подарочные карты',
    to: '/gift-cards',
  },
  {
    children: 'О нас',
    to: '/about',
  },
  {
    children: 'Контакты',
    to: '/contacts',
  },
];
