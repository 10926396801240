export default {
  socials: {
    ig: 'https://instagram.com/tezi_grooming',
    vk: 'https://vk.com/tezi_grooming',
    fb: 'https://www.facebook.com/tezigrooming/',
  },
  messengers: {
    wa: '#',
    vb: '#',
    tg: '#',
  },
  phone: {
    href: 'tel:+79661120222',
    text: '+ 7 (966) 11-20-222',
  },
  mail: {
    href: 'mailto:info@tezi-grooming.com',
    text: 'info@tezi-grooming.com',
  },
  address: 'м. Тульская, ул. Серпуховский Вал, 20',
  map: {
    coords: [55.712138, 37.6125000],
  },
};
