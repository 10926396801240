import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './mobile-menu-footer.scss';
import {
  Wa, Tg, Vb, PhoneIcon,
} from '../../contacts/contacts';

const MobileMenuFooter = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'mobile-menu__footer';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>

      <div className="mobile-menu__footer-title">Свяжитесь с нами удобным способом:</div>
      <ul className="mobile-menu__footer-list">
        <li className="mobile-menu__footer-item">
          <Wa className="mobile-menu__footer-link" />
        </li>
        <li className="mobile-menu__footer-item">
          <Vb className="mobile-menu__footer-link" />
        </li>
        <li className="mobile-menu__footer-item">
          <Tg className="mobile-menu__footer-link" />
        </li>
        <li className="mobile-menu__footer-item">
          <PhoneIcon className="mobile-menu__footer-link" />
        </li>
      </ul>
    </div>
  );
};

MobileMenuFooter.propTypes = {
  className: PropTypes.string,
};

MobileMenuFooter.defaultProps = {
  className: '',
};

export default MobileMenuFooter;
