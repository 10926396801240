import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import { Mail, Phone, Mark } from '../../contacts/contacts';
import './footer-top.scss';

const FooterTop = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'footer__top';
  const ROW_CLASS = 'footer__row';
  const COL_CLASS = 'footer__col';
  const TOP_COL_CLASS = 'footer__col_top';

  const MAIL_COL_CLASS = 'footer__col_mail';
  const PHONE_COL_CLASS = 'footer__col_phone';
  const ADDRESS_COL_CLASS = 'footer__col_address';

  const CONTACT_CLASS = 'footer__contact';

  const rootClass = mergeClasses(ROOT_CLASS, className);
  const colClass = mergeClasses(COL_CLASS, TOP_COL_CLASS);

  const mailClass = mergeClasses(colClass, MAIL_COL_CLASS);
  const phoneClass = mergeClasses(colClass, PHONE_COL_CLASS);
  const addressClass = mergeClasses(colClass, ADDRESS_COL_CLASS);

  return (
    <div className={rootClass}>

      <div className={ROW_CLASS}>
        <div className={phoneClass}>
          <Phone className={CONTACT_CLASS} />
        </div>

        <div className={mailClass}>
          <Mail className={CONTACT_CLASS} />
        </div>

        <div className={addressClass}>
          <Mark className={CONTACT_CLASS} />
        </div>
      </div>
    </div>
  );
};

FooterTop.propTypes = {
  className: PropTypes.string,
};

FooterTop.defaultProps = {
  className: '',
};

export default FooterTop;
