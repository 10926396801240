import React from 'react';
import './header-messengers.scss';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import {
  Wa as WaContact,
  Vb as VbContact,
  Tg as TgContact,
} from '../../contacts/contacts';

const HeaderMessengers = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'header__messengers';
  const ITEM_CLASS = 'header__messenger';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      <WaContact className={ITEM_CLASS} />
      <VbContact className={ITEM_CLASS} />
      <TgContact className={ITEM_CLASS} />
    </div>
  );
};

HeaderMessengers.propTypes = {
  className: PropTypes.string,
};

HeaderMessengers.defaultProps = {
  className: '',
};

export default HeaderMessengers;
