import React from 'react';
import PropTypes from 'prop-types';
import './mobile-menu-login.scss';
import { Arrow } from '../../icons/icons';

const MobileMenuLoginContainer = (props) => {
  const { text, icon } = props;
  const PHOTO_CLASS = 'mobile-menu__login-photo';
  const TEXT_CLASS = 'mobile-menu__login-text';
  const ARROW_ICON_CLASS = 'mobile-menu__login-arrow';

  const ArrowIcon = Arrow.right;

  return (
    <>
      <div className={PHOTO_CLASS}>{icon}</div>
      <div className={TEXT_CLASS}>{text}</div>
      <ArrowIcon className={ARROW_ICON_CLASS} />
    </>
  );
};

MobileMenuLoginContainer.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
};

MobileMenuLoginContainer.defaultProps = {
  text: '',
  icon: null,
};

export default MobileMenuLoginContainer;
