import React from 'react';
import PropTypes from 'prop-types';
import Burger from '../../burger/burger-open/burger-open';
import './header-burger.scss';
import { mergeClasses } from '../../../../libs/helpers';

const HeaderBurger = (props) => {
  const { className, active, onClick } = props;
  const ROOT_CLASS = 'header__burger';
  const ACTIVE_CLASS = active ? 'header__burger_active' : null;

  const rootClass = mergeClasses(ROOT_CLASS, className, ACTIVE_CLASS);

  return (
    <Burger className={rootClass} onClick={onClick} />
  );
};

HeaderBurger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

HeaderBurger.defaultProps = {
  className: '',
  active: false,
  onClick: () => {},
};

export default HeaderBurger;
