import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';
import { mergeClasses } from '../../../libs/helpers';

require('./logo.svg');

const Logo = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'logo';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      <svg className="logo__img">
        <use xlinkHref="#logo" />
      </svg>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
