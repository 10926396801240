import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Logo from './header-logo/header-logo';
import Burger from './header-burger/header-burger';
import Messengers from './header-messengers/header-messengers';
import Phone from './header-phone/header-phone';
import Address from './header-address/header-address';
import Login from './header-login/header-login';
import MobileMenu from '../mobile-menu/mobile-menu';
import Menu from './header-menu/header-menu';
import './header.scss';
import { mergeClasses } from '../../../libs/helpers';

const Header = (props) => {
  const { theme } = props;

  const ROOT_CLASS = 'header';
  const ROOT_THEME_CLASS = theme ? `${ROOT_CLASS}_theme_${theme}` : null;
  const rootClass = mergeClasses(ROOT_CLASS, ROOT_THEME_CLASS);

  const [isMenuOpen, setMenuOpenState] = useState(false);

  const handleBurgerClick = () => {
    setMenuOpenState(true);
  };

  const handleCloseMenu = () => {
    setMenuOpenState(false);
  };

  return (
    <>
      <header className={rootClass}>
        <div className="header__container container header__main-line">
          <Logo />
          <Messengers />
          <Phone />
          <Address />
          <Login isLogin />
          <Burger onClick={handleBurgerClick} />
        </div>
        <div className="header__container container header__menu-wrap">
          <Menu />
        </div>
      </header>
      <MobileMenu handleCloseMenu={handleCloseMenu} isOpen={isMenuOpen} />
    </>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

Header.defaultProps = {
  theme: '',
};

export default Header;
