import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from '../base-button/base-button';

const Button = (props) => {
  const {
    outline,
    iconLeft,
    iconRight,
    ...other
  } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading,react/button-has-type
  const WrappedButton = (childProps) => <button {...other} {...childProps}>{childProps.children}</button>;

  return BaseButton(WrappedButton, props);
};


Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
