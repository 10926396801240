import React from 'react';
import { Link } from 'gatsby';
import Logo from '../../logo/logo';
import './header-logo.scss';

const HeaderLogo = () => {
  const ROOT_CLASS = 'header__logo';

  return <Link to="/" aria-label="Логотип компании Тези"><Logo className={ROOT_CLASS} /></Link>;
};

export default HeaderLogo;
