import React from 'react';
import PropTypes from 'prop-types';

import '../styles/common.scss';
import Header from './header/header';
import Footer from './footer/footer';
import { mergeClasses } from '../../libs/helpers';

const Layout = (props) => {
  const {
    children,
    pageName,
    mainClass,
    headerTheme,
  } = props;

  const ROOT_CLASS = 'page';
  const rootClass = mergeClasses(ROOT_CLASS, pageName);

  const MAIN_CLASS = 'page__main';
  const mainClassName = mergeClasses(MAIN_CLASS, mainClass);

  return (
    <div className={rootClass}>
      <Header theme={headerTheme} />
      <main className={mainClassName}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired, // todo alex точно node?
  pageName: PropTypes.string,
  mainClass: PropTypes.string,
  headerTheme: PropTypes.string,
};

Layout.defaultProps = {
  pageName: '',
  mainClass: '',
  headerTheme: '',
};

export default Layout;
