import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { SimpleArrow as Arrow } from '../icons/icons';
import { mergeClasses } from '../../../libs/helpers';
import './back.scss';

const Back = (props) => {
  const { className, href, text } = props;

  const ROOT_CLASS = 'back';
  const LINK_CLASS = 'back__link';
  const WRAP_CLASS = 'back__wrap';
  const ICON_CLASS = 'back__icon';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const Icon = Arrow.left;

  return (
    <div className={rootClass}>
      <Link className={WRAP_CLASS} type="button" to={href}>
        <Icon className={ICON_CLASS} />
        <span className={LINK_CLASS}>{text}</span>
      </Link>
    </div>
  );
};

Back.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
};

Back.defaultProps = {
  className: '',
  text: 'Назад',
};

export default Back;
