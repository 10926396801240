import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './mobile-menu-logo.scss';
import Logo from '../../header/header-logo/header-logo';

const MobileMenuLogo = (props) => {
  const { className } = props;
  const ROOT_CLASS = 'mobile-menu__logo';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <Logo className={rootClass} />
  );
};

MobileMenuLogo.propTypes = {
  className: PropTypes.string,
};

MobileMenuLogo.defaultProps = {
  className: '',
};

export default MobileMenuLogo;
