import React from 'react';
import './mobile-menu-login.scss';
import { User } from '../../icons/icons';
import LoginContainer from './mobile-menu-login-container';

const ICON_WRAP_CLASS = 'mobile-menu__login-icon-wrap';
const ICON_CLASS = 'mobile-menu__login-icon';
const IconWrap = <div className={ICON_WRAP_CLASS}><User className={ICON_CLASS} /></div>;

const Enter = () => <LoginContainer icon={IconWrap} text="Войти" />;

export default Enter;
