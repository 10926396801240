import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './mobile-menu-close.scss';
import Burger from '../../burger/burger-close/burger-close';

const MobileMenuClose = (props) => {
  const { className, onClick } = props;
  const ROOT_CLASS = 'mobile-menu__close';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <Burger className={rootClass} onClick={onClick} />
  );
};

MobileMenuClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

MobileMenuClose.defaultProps = {
  className: '',
  onClick: () => {},
};

export default MobileMenuClose;
