import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../../libs/helpers';
import './menu-item.scss';

const MenuItem = (props) => {
  const { className, children } = props;
  const ROOT_CLASS = 'menu__item';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return <li className={rootClass}>{children}</li>;
};

MenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

MenuItem.defaultProps = {
  className: '',
  children: null,
};

export default MenuItem;
